<template>
  <div class="app-container" ref="container">
    <!-- <div style="width:100%;height:100%;position:absolute;padding-top:30px;"> background:rgba(255, 255, 255, 0.5);-->
    <div style="width:100%;height:100%;position:absolute;" v-bind:style1="{height: mapheigt}">
    <div id="map-containergs" style="width:100%;height:100%;"/>
    </div>
    <div style="padding-top:12px;padding-right:20px;position:absolute;" v-if="canEditAddress">
    <el-row ref="toolbar">
      <el-col :span1="24" >
        <el-form size=mini :model="form" >
          <div style="display: flex;align-items: center;">
            <el-form-item size=small label=""  label-width="12px" prop="address">
              <el-input  prefix-icon="el-icon-location" v-model="form.address" placeholder="站点" clearable 
              style="width: 100px;" ></el-input>
            </el-form-item>
            <el-form-item label-width="12px">
              <el-button type="success" size="small" icon="el-icon-edit-outline" @click="saveaddress">保存</el-button>
            </el-form-item>
          </div>
        </el-form>
      </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
/* eslint-disable */
//import loadBMap from '../../plugins/loadBMap.js'
export default {
  data() {
    return {
      mapheigt:"500px",
      form: {
        address: '', // 详细地址
        addrPoint: { // 详细地址经纬度
          lng: 0,
          lat: 0
        }
      },
      map: '', // 地图实例
      mk: '', // Marker实例
      locationPoint: null,
      stationList:[],
      markers:[],
      canEditAddress:false,
    }
  },
  async mounted() {
    this.initmapheight();
    //await loadBMap('8ktYGStGratzruLRGVenOKpM7gAn39d6') // 加载引入BMap
    this.initMap()
    window.onresize = () => {
      this.initmapheight();
      };
  },
  methods: {
    // 初始化地图
    initMap() {
var that=this
      // 1、挂载地图
      that.mapgs = new BMap.Map('map-containergs', { enableMapClick: true,minZoom: 5,maxZoom: 20 })
      var point = new BMap.Point(121.67968256587015,31.538427690383923)
      that.mapgs.centerAndZoom(new BMap.Point(121.64705610974767,31.611031028703508), 12)
      that.mapgs.enableScrollWheelZoom(true);
      // 3、设置图像标注并绑定拖拽标注结束后事件
      that.mk = new BMap.Marker(point, { enableDragging: false })
      that.mapgs.addOverlay(this.mk)
      // this.mk.addEventListener('dragend', function(e) {
      //   this.getAddrByPoint(e.point)
      // })
      that.mk.setAnimation(BMAP_ANIMATION_BOUNCE);
      that.mapgs.addControl(new BMap.GeolocationControl({anchor: BMAP_ANCHOR_BOTTOM_LEFT})) //创建一个地图定位控件

      // 4、添加（右上角）平移缩放控件
      that.mapgs.addControl(new BMap.NavigationControl({ anchor: BMAP_ANCHOR_TOP_RIGHT, type: BMAP_NAVIGATION_CONTROL_SMALL }))
      //setTimeout(() => {
              //this.getstation();
            //}, 300);
    },
    // 获取站点列表
    getstation() {
 
      let data = {
        lx: "getstation",
        procext:"yhjj"
      };
      this.$api.post(
        "",
        data,
        (res) => {
          if (res["status"] == "1") {
            this.markers=[];
            this.stationList = res["records"];
            this.canEditAddress=res["canedit"]==="0" ? false : true;
            let iconImg = new BMap.Icon("http://www.yh59481717.com/Images/map-6.png", new BMap.Size(44, 50)); 
					  this.stationList.forEach((s,index) => {
               let p1=s.m_mapxy.split("|")[0];
               let p2=s.m_mapxy.split("|")[1];
               let point = new BMap.Point(p1,p2);
               let label = new BMap.Label("<div style='padding:1px;font-size:9px;'>"+s.TypeName+"</div>",{point:new BMap.Point(p1,p2),offset:new BMap.Size(3,-23)});
               label.setStyle({borderColor:"#808080",color:"#333",cursor:"pointer"});
               let marker = new BMap.Marker(point, { enableDragging: false,icon:iconImg });
               marker.setLabel(label);

               if (this.canEditAddress){
                  marker.addEventListener('dragend', function(e) {
                    this.getAddrByPoint(e.point,index);
                  })
                  marker.addEventListener('click', function(e) {
                    this.EditAddrByPoint(e.point,index);
                  })
               }
               
               this.markers.push(marker);
               this.mapgs.addOverlay(marker);
            })
          }
        },
        (failure) => {}
      );
      
    },
    saveaddress() {
      let savelist=this.stationList.filter(item => {
				return item.ismodi==="1";
			})
      if (savelist.length==0){
        this.$message.warning("记录未变动，无需保存");
        return;
      }
      this.$confirm("真的要保存吗?", "确认操作", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "info",
          iconClass: "el-icon-question",
        })
        .then(() => {
          let data = {
            lx: "savestation",
            procext:"yhjj",
            sqlone: JSON.stringify(savelist),
          };
          this.$api.post("",data,
            (res) => {
              if (res["status"] == "1"){
                this.$message.success(res["message"]);
                this.stationList.forEach((s) => {
                  s.ismodi="0";
                })
              }else
                this.$message.error(res["message"]);
            },
            (failure) => {
              //this.showUserInfo = false;
              this.$message.error(failure);
            }
          );
        })
        .catch(() => {});
    },
    EditAddrByPoint(point,index) {
       //var this = this
       
       this.form.address =this.stationList[index].TypeName;
       this.markers[index].enableDragging();//marker.disableDragging(); 
      // var geco = new BMap.Geocoder()
      // geco.getLocation(point, function(res) {
      //   console.log(res)
      //   this.mk.setPosition(point)
      //   this.mapgs.panTo(point)
      //   this.form.address = res.address
      //   this.form.addrPoint = point
      // })
    },
    // 2、拖动时保存地址
    getAddrByPoint(point,index) {
       //var this = this
       console.log(index);
       this.form.address =this.stationList[index].TypeName;
       this.stationList[index].m_mapxy=point.lng+'|'+point.lat;
       this.stationList[index].ismodi="1";
      // var geco = new BMap.Geocoder()
      // geco.getLocation(point, function(res) {
      //   console.log(res)
      //   this.mk.setPosition(point)
      //   this.mapgs.panTo(point)
      //   this.form.address = res.address
      //   this.form.addrPoint = point
      // })
    },
    // 8-1、地址搜索
    querySearchAsync(str, cb) {
      var options = {
        onSearchComplete: function(res) {
          var s = []
          if (local.getStatus() == BMAP_STATUS_SUCCESS) {
            for (var i = 0; i < res.getCurrentNumPois(); i++) {
              s.push(res.getPoi(i))
            }
            cb(s)
          } else {
            cb(s)
          }
        }
      }
      var local = new BMap.LocalSearch(this.mapgs, options)
      local.search(str)
    },
    // 8-2、选择地址
    handleSelect(item) {
      this.form.address = item.address + item.title
      this.form.addrPoint = item.point
      this.mapgs.clearOverlays()
      this.mk = new BMap.Marker(item.point)
      this.mapgs.addOverlay(this.mk)
      this.mapgs.panTo(item.point)
    },
    getLocalPosition(){
      navigator.geolocation.getCurrentPosition(function (position) {
      //得到html5定位结果
      var x = position.coords.longitude;
      var y = position.coords.latitude;
      
      //由于html5定位的结果是国际标准gps，所以from=1，to=5
      //下面的代码并非实际是这样，这里只是提供一个思路
      // BMap.convgps(x, y, 1, 5, function (convRst) {
      //    var point = new BMap.Point(convRst.x, convRst.y);
      //    //这个部分和上面的代码是一样的
      //    var marker = new BMap.Marker(point);
      //    map.addOverlay(marker);
      //    map.panTo(point);
      //   })
     })
    },
    
  },
  created() {
    //this.getLocalPosition();
  }
}
</script>
<style>

.app-container {
  width: 100%;
  height: 100%;
}
/* .toolbar{
  background:rgba(255, 255, 255, 0.65) !important; 
} */
</style>
<style lang="scss" scoped>
  .autoAddressClass{
    li {
      i.el-icon-search {margin-top:11px;}
      .mgr10 {margin-right: 10px;}
      .title {
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .address {
        line-height: 1;
        font-size: 12px;
        color: #b4b4b4;
        margin-bottom: 5px;
      }
    }
  }
</style>

